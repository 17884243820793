<template>
  <el-dialog
    v-if="userShow"
    title="指定下级审批人"
    :visible.sync="userShow"
    :modal-append-to-body="false"
    :before-close="closeDialog"
    top="9vh"
    width="70%">
    <ProcessUserDeptDialog v-if="!isMultiInstance && approveIds" ref="processUserDept" @row-data="getUser"
                           :approveIds="approveIds"></ProcessUserDeptDialog>
    <ProcessUserDeptSelectionDialog v-if="isMultiInstance && approveIds" @select-data="getUserSelection"
                                    :approveIds="approveIds"></ProcessUserDeptSelectionDialog>
    <UserDetpDialog
      v-if="!approveIds"
      ref="UserDeptMultipleDialog"
      @select-data="selectData"
    ></UserDetpDialog>
    <div slot="footer" v-if="approveIds">
      <el-button @click="closeDialog">{{ $t("cip.cmn.btn.celBtn") }}</el-button>
      <el-button @click="setCurrent()" type="warning" v-if="!isMultiInstance">取消选择</el-button>
      <el-button @click="headConfirm" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import ProcessUserDeptDialog from "@/views/components/UserDeptDialog/processUserDeptDialog.vue";
import ProcessUserDeptSelectionDialog from "@/views/components/UserDeptDialog/processUserDeptSelectionDialog.vue";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";

export default {
  name: "processUserDialog",
  components: {
    UserDetpDialog,
    ProcessUserDeptDialog,
    ProcessUserDeptSelectionDialog
  },
  data() {
    return {
      userIds: '',
      approveIds: '',
      userShow: false,
      isMultiInstance: false,
    }
  },
  methods: {
    closeDialog() {
      this.userShow = false;
      this.$emit('closeProcess')
    },
    initDialog(row) {
      this.approveIds = row.approveIds
      this.isMultiInstance = row.isMultiInstance
      this.userIds = '';
      this.userShow = true
    },
    selectData(row) {
      this.userIds = row.id;
      this.headConfirm()
    },
    getUserSelection(data) {
      this.userIds = data;
    },
    getUser(data) {
      this.userIds = data.id;
    },
    setCurrent(row) {
      this.$refs.processUserDept.$refs.gridLayout.$refs.grid.setCurrentRow(row);
    },
    headConfirm() {
      // if(this.userIds == '')return this.$message.error('请选择下级审批人')
        this.userShow = false
        this.$emit("getUserSelection", this.userIds)
    },
  },
  mounted() {
  },
}
</script>
