<template>
  <avue-crud
             :option="tableOptions"
             :table-loading="tableLoading"
             :data="tableData"
             ref="crud"
             @row-click="currentRowChange"
             @on-load="onLoad">
    <template  slot-scope="{row}"  slot="radio">
      <el-radio v-model="selectRow" :label="row.$index"><i></i></el-radio>
    </template>
  </avue-crud>
</template>

<script>
import { selectUserByIds} from "@/api/system/user";
import GridLayout from "@/views/components/layout/grid-layout";

export default {
  name: "user",
  components: {
    GridLayout,
  },
  props: {
    deptCategory: [],
    userDeptType: {
      type: String,
      default: ""
    },
    approveIds:{
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      selectRow: '',
      tableData: [],
      tableLoading: true,
      selectionList: [],
      tableOptions: {
        height:400,
        highlightCurrentRow: true,
        customRowAddBtn: false,
        menu: false,
        addBtn: false,
        header: false,
        border: true,
        stripe: true,
        selection: false,
        column: [
          {
            label: '',
            align: 'center',
            prop: 'radio',
            type: 'radio',
            width: 55,
            hide: false
          },
          {
            label: "用户账号",
            prop: "account",
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "主组织",
          //   prop: "masterOrganizationName",
          //   align: 'left',
          //   overHidden: true,
          // },
          // {
          //   label: "用户类型",
          //   prop: "personnelType",
          //   type: "select",
          //   width: 200,
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   align: 'left',
          //   dataType: 'string',
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type"
          // },
        ],
      },
    };
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    currentRowChange(data){
      this.selectRow = data.$index
      this.$emit('row-data', data);
    },
    onLoad() {
      this.tableLoading = true;
      let formData=new FormData()
      formData.append('ids',this.approveIds)
      selectUserByIds(formData).then(res => {
        this.tableData = res.data.data;
        this.tableLoading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table--small td, .el-table--small th{
  line-height: 26px;
}
::v-deep .el-table .cell {
  line-height: 32px;
}
::v-deep .el-table--border {
  border-bottom: 1px solid #cccccc ;
}
</style>
